// Contact Us
.gg-contact {
    // Update this fallback color and gradient??
    background-color: #f4f3ef;
    background-image: linear-gradient(to bottom,transparent 0%,rgba(112,112,112,0) 60%,rgba(186,186,186,0.15) 100%);

    .container {
        max-width: 675px;
    }

    &__form {
        margin-top: 4rem;
        padding: 0 1rem;
        position: relative;
    }

    &__form-row:not(:first-child) {
        margin-top: 1rem;
    }

    &__form-row > div:not(:first-child) {
        margin-top: 1rem;
    }

    &__btn-wrap {
        margin-top: 1.75rem;
    }

    &__spinner-wrap, &__completed-wrap {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
        background-color: #f4f3ef;
        background-image: linear-gradient(to bottom,transparent 0%,rgba(112,112,112,0) 60%,rgba(186,186,186,0.15) 100%);
    }

    &__spinner-wrap {
        align-items: center;
    }

    &__completed-wrap {
        align-items: start;
    }

    &__spinner {
        height: 7rem;
        width: 7rem;
        border: 0.65rem solid $gg-blue;
        border-right-color: transparent;
        border-radius: 50%;
        margin: 0 auto;
        animation: rotate 0.8s infinite linear;
    }

    @media (min-width: $gg-screen-md) {

        &__form {
            padding: 0;
        }

        &__form-row > div:not(:first-child) {
            margin-top: 0;
        }

    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}