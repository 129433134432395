// Header styles
.gg-header {
    height: 4rem;
    padding: 0;
    background-color: $gg-header-bg;

    &__menu-btn {
        position: absolute;
        top: 0;
        right: 0;
        height: 4rem;
        width: 5rem;
        border: 0;
        border-radius: 0;
        background: $gg-navy;
        color: $white;
    }

    &__menu-btn:hover, &__menu-btn:focus {
        color: $gg-navy;
        box-shadow: inset 0 0 0 2em $white;
    }

    /* &__menu-btn:focus {
        box-shadow: none;
    } */
}

.gg-navbar {

    &__brand {
        height: 3rem;
        overflow: hidden;
        margin-right: 2.5rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        line-height: 1.5rem;
        // Temp weight until we get a logo in here
        font-weight: $gg-fw-semibold;

        img {
            //width: 12.5rem;
            margin-top: -2rem;
            height: 6rem;
        }
    }
    
    &__nav .gg-navbar {

        &__link {
            // color: $white;
            width: fit-content;
            color: $gg-navy;
            font-size: 1rem;
            font-weight: $gg-fw-semibold;

            &:not(:first-child) {
                margin-top: 1rem;
            }

            &:before {
                // background-color: $white;
                background-color: $gg-navy;
                margin-bottom: 0.25rem;
            }

            &:hover, &:hover > * {
                // color: $white;
                color: $gg-navy;
            }

            &.active, &:visited, &:visited > * {
                // color: $white;
                color: $gg-navy;
            }
        }
    }

    &__portal-btn {
        margin-top: 2.5rem;
        padding: 8px 1.5rem;
    }

    @media (min-width: $gg-screen-lg) {
        &__nav .gg-navbar {

            &__link {
                &:not(:first-child) {
                    margin-top: 0;
                    margin-left: 1rem;
                }
            }
        }

        &__portal-btn {
            margin-top: 0;
        }
    }

}

.offcanvas-header, .offcanvas-body {
    padding-left: 1.5rem;
    padding-right: 1.5rem;

    /* @media (min-width: $gg-screen-lg) {
        padding-left: 0;
        padding-right: 0;
    } */
}

.offcanvas-header .btn-close:hover,
.offcanvas-header .btn-close:focus {
    box-shadow: none;
    color: $gg-dark-gray;
}