// My original font choice:
// @import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600;700;800&display=swap');

// Requested font choices:
@import url('https://fonts.googleapis.com/css2?family=Public+Sans:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800&display=swap');

@import './settings/settings';


body {
    font-size: 16px;
    //color: $gg-deep-blue;
    // color: $gg-dark-gray;
    color: $gg-navy;
    font-family: 'Public Sans', sans-serif;
    // min-height: 100vh;
    letter-spacing: 0.5px;
}

a {
    text-decoration: none;
}

p {
    margin-bottom: 0;
    font-size: 1rem;
    line-height: 1.3125rem;
}

h1, h2, h3 {
    font-family: 'Playfair Display', 'Public Sans', sans-serif;
}

h1 {
    margin-bottom: 0;
    font-size: 2.75rem;
    line-height: 3.75rem;
    letter-spacing: 1px;

    @media(min-width: 425px) {
        font-size: 3rem;
        line-height: 4rem;
    }

    @media (min-width: $gg-screen-md) {
        font-size: 3.5rem;
        line-height: 4.5rem;
    }

    @media (min-width: $gg-screen-lg) {
        font-size: 4rem;
        line-height: 5rem;
    }
}

h2 {

    // margin-bottom: 0;
    font-size: 2rem;
    line-height: 3rem;

    @media (min-width: $gg-screen-md) {
        font-size: 2.5rem;
        line-height: 3.5rem;
    }

    @media (min-width: $gg-screen-lg) {
        font-size: 3rem;
        line-height: 4rem;
    }

}

section, .gg-section {
    padding: 7rem 0 8rem;

    @media (min-width: $gg-screen-largest) {
        .container {
            max-width: 1170px;
        }
    }
}


// Button Styles
button, .gg-btn {
    /* font-family: 'Public Sans', sans-serif; */
    border: 2px solid;
    border-radius: 3px;
    background: none;
    outline: none;
    // padding: 12px 24px;
    padding: 1rem 1.5rem;
    // color: $gg-deep-blue;
    color: $gg-navy;
    font-size: 1.125rem;
    line-height: 1;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    transition: 0.3s;
  
    &:hover,
    &:focus { 
        // border-color: adjust-hue($gg-deep-blue, 45deg);
        border-color: $gg-navy;
        color: $white;
        // box-shadow: inset 0 0 0 2em adjust-hue($gg-deep-blue, 45deg);
        box-shadow: inset 0 0 0 2em $gg-navy;
    }
}

.gg-btn--light {
    color: darken($gg-offwhite, 10%);

    &:hover,
    &:focus { 
        border-color: adjust-hue($gg-offwhite, 45deg);
        // color: $gg-deep-blue;
        color: $gg-navy;
        box-shadow: inset 0 0 0 2em adjust-hue($gg-offwhite, 45deg);
    }
}
// END Button styles 


// Text Link Styles
.gg-text-link {
    /* font-family: 'Public Sans', sans-serif; */
    overflow: hidden;
    position: relative;
    display: inline-block;
    text-decoration: none;
    vertical-align: top;
    color: $gg-link-blue;
    font-size: 1.125rem;
    line-height: 1.5rem;

    /* &--light {
        color: $white;
    } */

    &:before, &:after {
        content: '';
        position: absolute;
        width: 100%;
        left: 0;
    }

    &:before {
        background-color: $gg-link-blue-hover;
        height: 2px;
        bottom: 0;
        transform-origin: 100% 50%;
        transform: scaleX(0);
        transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
    }

    &:after {
        content: attr(data-replace);
        height: 100%;
        top: 0;
        transform-origin: 100% 50%;
        transform: translate3d(200%, 0, 0);
        transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
        color: $gg-link-blue-hover;
    }

    // This would get removed if we use the data-replace attribute to sub out the text content
    &:hover {
        color: $gg-link-blue-hover;
    }

    &:hover:before {
        transform-origin: 0% 50%;
        transform: scaleX(1);
    }

    &:hover:after {
        transform: translate3d(0, 0, 0);
    }

    span {
        display: inline-block;
        transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
    }

    :hover span {
        transform: translate3d(-200%, 0, 0);
    }

}
// END Text Link Styles


// Input styles
label {
    margin-bottom: 0.3125rem;
    font-weight: $gg-fw-semibold;
}

input, .form-control {
    // background-color: $gg-gray;
    border: medium;
    border-radius: 0;
    color: $gg-slate;
    font-size: 16px;
    transition: background-color .3s ease 0s;
    padding: 7px 18px;
    min-height: 40px;
    -webkit-box-shadow: none;
    box-shadow: none;

    &::placeholder {
        color: rgba(63, 77, 99, 0.4);
        // color: white;
    }

    &:active, &:focus {
        // background-color: lighten($gg-slate, 15%);
        color: $gg-slate;
    }
}
  
  
@import './pages/pages';
@import './components/components';