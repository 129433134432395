// Links
.gg-links {
    /* background: $gg-deep-blue;
    background: linear-gradient(to bottom, rgba(32,32,34,1) 70%, rgba(32,32,34,0.9) 100%); */
    background: darken($gg-dark-gray, 7.5%);
    color: $white;

    &__desc {
        margin-top: 1rem;
        padding: 0 1rem;
        font-size: 1.25rem;
        line-height: 1.65rem;
    }

    &__body {
        margin-top: 4rem;
    }

    &__body > div:not(:first-child) {
        margin-top: 3.5rem;
    }

    &__wrap {
        margin-top: 2rem;
        padding: 0 0.5rem;
    }

    .gg-text-link:not(:first-child) {
        margin-top: 1.5rem;
    }

    @media (min-width: $gg-screen-md) {
        &__desc {
            padding: 0;
        }

        &__body > div:not(:first-child) {
            margin-top: 0;
        }
    }
}
// END Links