// Services
.gg-services {
    // background-color: $gg-light-blue;
    background-color: $gg-light-gray;
    // color: $white;

    &__wrap {
        margin-top: 4rem;
    }

    &__service {
        padding: 0 1rem;
    }

    .col-md-6:not(:first-child) {
        margin-top: 3.5rem;
    }

    &__icon {
        font-size: 5rem;
        // color: white;
    }

    &__name {
        margin-top: 1rem;
    }

    &__desc {
        margin-top: 1rem;
        font-size: 1.125rem;
        line-height: 1.5rem;
    }

    @media (min-width: $gg-screen-md) {
        &__service {
            padding: 0 1.5rem;
        }

        .col-md-6:not(:first-child) {
            margin-top: 0;
        }

        .col-md-6:nth-child(n+3) {
            margin-top: 4.5rem;
        }
    }
}
// END Services