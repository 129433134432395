// Generic Colors
$white: #FFFFFF;
$black: #000000;

// My Original Brand Color Palette:
$gg-blue: #274b69;
$gg-light-blue: #85a1c1;
$gg-gray: #c6ccd8;
$gg-slate: #3f4d63;
$gg-deep-blue: #202022;

// Requested Brand Color Palette:
$gg-navy: #031D44;
$gg-dark-gray: #444343;
$gg-light-gray: #CCD8DB;

// Others
$gg-offwhite: darken(white, 10%);

// Link Colors
// $gg-link-blue: #54b3d6;
$gg-link-blue: #6fbfdc;
$gg-link-blue-hover: lighten($gg-link-blue, 15%);

// Header / Navbar Colors
// $gg-header-bg: $gg-light-blue;
$gg-header-bg: $gg-light-gray;

// Modal Colors
$gg-modal-overlay: rgba(31, 32, 41, .75);

// Gradients
$gg-linear-gradient: linear-gradient(269.89deg, #FFFFFF 32.27%, $gg-gray 64.03%, #FFFFFF 82.77%);