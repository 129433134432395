@import url("https://fonts.googleapis.com/css2?family=Public+Sans:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800&display=swap");
/* .fm-effra {
    font-family: 'Effra';
} */
.fm-fw-normal {
  font-weight: 400;
}

.fm-fw-medium {
  font-weight: 500;
}

.fm-fw-semibold {
  font-weight: 600;
}

.fm-fw-bold {
  font-weight: 700;
}

.fm-fw-xbold {
  font-weight: 800;
}

.fm-fw-heavy {
  font-weight: 900;
}

body {
  font-size: 16px;
  color: #031D44;
  font-family: 'Public Sans', sans-serif;
  letter-spacing: 0.5px;
}

a {
  text-decoration: none;
}

p {
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.3125rem;
}

h1, h2, h3 {
  font-family: 'Playfair Display', 'Public Sans', sans-serif;
}

h1 {
  margin-bottom: 0;
  font-size: 2.75rem;
  line-height: 3.75rem;
  letter-spacing: 1px;
}

@media (min-width: 425px) {
  h1 {
    font-size: 3rem;
    line-height: 4rem;
  }
}

@media (min-width: 768px) {
  h1 {
    font-size: 3.5rem;
    line-height: 4.5rem;
  }
}

@media (min-width: 992px) {
  h1 {
    font-size: 4rem;
    line-height: 5rem;
  }
}

h2 {
  font-size: 2rem;
  line-height: 3rem;
}

@media (min-width: 768px) {
  h2 {
    font-size: 2.5rem;
    line-height: 3.5rem;
  }
}

@media (min-width: 992px) {
  h2 {
    font-size: 3rem;
    line-height: 4rem;
  }
}

section, .gg-section {
  padding: 7rem 0 8rem;
}

@media (min-width: 1440px) {
  section .container, .gg-section .container {
    max-width: 1170px;
  }
}

button, .gg-btn {
  /* font-family: 'Public Sans', sans-serif; */
  border: 2px solid;
  border-radius: 3px;
  background: none;
  outline: none;
  padding: 1rem 1.5rem;
  color: #031D44;
  font-size: 1.125rem;
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

button:hover, button:focus, .gg-btn:hover, .gg-btn:focus {
  border-color: #031D44;
  color: #FFFFFF;
  -webkit-box-shadow: inset 0 0 0 2em #031D44;
          box-shadow: inset 0 0 0 2em #031D44;
}

.gg-btn--light {
  color: #cccccc;
}

.gg-btn--light:hover, .gg-btn--light:focus {
  border-color: #e6e6e6;
  color: #031D44;
  -webkit-box-shadow: inset 0 0 0 2em #e6e6e6;
          box-shadow: inset 0 0 0 2em #e6e6e6;
}

.gg-text-link {
  /* font-family: 'Public Sans', sans-serif; */
  overflow: hidden;
  position: relative;
  display: inline-block;
  text-decoration: none;
  vertical-align: top;
  color: #6fbfdc;
  font-size: 1.125rem;
  line-height: 1.5rem;
  /* &--light {
        color: $white;
    } */
}

.gg-text-link:before, .gg-text-link:after {
  content: '';
  position: absolute;
  width: 100%;
  left: 0;
}

.gg-text-link:before {
  background-color: #addaeb;
  height: 2px;
  bottom: 0;
  -webkit-transform-origin: 100% 50%;
          transform-origin: 100% 50%;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
  transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
  transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1), -webkit-transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
}

.gg-text-link:after {
  content: attr(data-replace);
  height: 100%;
  top: 0;
  -webkit-transform-origin: 100% 50%;
          transform-origin: 100% 50%;
  -webkit-transform: translate3d(200%, 0, 0);
          transform: translate3d(200%, 0, 0);
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
  transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
  transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1), -webkit-transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
  color: #addaeb;
}

.gg-text-link:hover {
  color: #addaeb;
}

.gg-text-link:hover:before {
  -webkit-transform-origin: 0% 50%;
          transform-origin: 0% 50%;
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
}

.gg-text-link:hover:after {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.gg-text-link span {
  display: inline-block;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
  transition: -webkit-transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
  transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
  transition: transform 0.3s cubic-bezier(0.76, 0, 0.24, 1), -webkit-transform 0.3s cubic-bezier(0.76, 0, 0.24, 1);
}

.gg-text-link :hover span {
  -webkit-transform: translate3d(-200%, 0, 0);
          transform: translate3d(-200%, 0, 0);
}

label {
  margin-bottom: 0.3125rem;
  font-weight: 600;
}

input, .form-control {
  border: medium;
  border-radius: 0;
  color: #3f4d63;
  font-size: 16px;
  -webkit-transition: background-color .3s ease 0s;
  transition: background-color .3s ease 0s;
  padding: 7px 18px;
  min-height: 40px;
  -webkit-box-shadow: none;
  box-shadow: none;
}

input::-webkit-input-placeholder, .form-control::-webkit-input-placeholder {
  color: rgba(63, 77, 99, 0.4);
}

input:-ms-input-placeholder, .form-control:-ms-input-placeholder {
  color: rgba(63, 77, 99, 0.4);
}

input::-ms-input-placeholder, .form-control::-ms-input-placeholder {
  color: rgba(63, 77, 99, 0.4);
}

input::placeholder, .form-control::placeholder {
  color: rgba(63, 77, 99, 0.4);
}

input:active, input:focus, .form-control:active, .form-control:focus {
  color: #3f4d63;
}

.gg-my-story {
  position: relative;
  background-color: #f4f3ef;
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(60%, rgba(112, 112, 112, 0)), to(rgba(186, 186, 186, 0.15)));
  background-image: linear-gradient(to bottom, transparent 0%, rgba(112, 112, 112, 0) 60%, rgba(186, 186, 186, 0.15) 100%);
}

.gg-my-story__avatar {
  width: 15.5rem;
  height: 20rem;
  background-color: #CCD8DB;
  background-image: url("../assets/images/headshot.jpg");
  background-position: center top;
  background-size: cover;
  overflow: hidden;
  -webkit-box-shadow: -50px -50px 0 -40px #85a1c1, 50px 50px 0 -40px #274b69;
          box-shadow: -50px -50px 0 -40px #85a1c1, 50px 50px 0 -40px #274b69;
}

.gg-my-story__avatar img {
  width: 100%;
  height: 100%;
}

.gg-my-story__heading {
  margin-top: 2.5rem;
}

.gg-my-story__desc {
  margin-top: 2.5rem;
  padding: 0 1rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

.gg-my-story__desc p {
  margin-top: 1.5rem;
}

.gg-my-story__desc img {
  margin: 3rem auto;
  -webkit-box-shadow: -50px -50px 0 -40px #85a1c1, 50px 50px 0 -40px #274b69;
          box-shadow: -50px -50px 0 -40px #85a1c1, 50px 50px 0 -40px #274b69;
}

@media (min-width: 768px) {
  .gg-my-story__desc {
    padding: 0;
  }
}

.gg-header {
  height: 4rem;
  padding: 0;
  background-color: #CCD8DB;
  /* &__menu-btn:focus {
        box-shadow: none;
    } */
}

.gg-header__menu-btn {
  position: absolute;
  top: 0;
  right: 0;
  height: 4rem;
  width: 5rem;
  border: 0;
  border-radius: 0;
  background: #031D44;
  color: #FFFFFF;
}

.gg-header__menu-btn:hover, .gg-header__menu-btn:focus {
  color: #031D44;
  -webkit-box-shadow: inset 0 0 0 2em #FFFFFF;
          box-shadow: inset 0 0 0 2em #FFFFFF;
}

.gg-navbar__brand {
  height: 3rem;
  overflow: hidden;
  margin-right: 2.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  line-height: 1.5rem;
  font-weight: 600;
}

.gg-navbar__brand img {
  margin-top: -2rem;
  height: 6rem;
}

.gg-navbar__nav .gg-navbar__link {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: #031D44;
  font-size: 1rem;
  font-weight: 600;
}

.gg-navbar__nav .gg-navbar__link:not(:first-child) {
  margin-top: 1rem;
}

.gg-navbar__nav .gg-navbar__link:before {
  background-color: #031D44;
  margin-bottom: 0.25rem;
}

.gg-navbar__nav .gg-navbar__link:hover, .gg-navbar__nav .gg-navbar__link:hover > * {
  color: #031D44;
}

.gg-navbar__nav .gg-navbar__link.active, .gg-navbar__nav .gg-navbar__link:visited, .gg-navbar__nav .gg-navbar__link:visited > * {
  color: #031D44;
}

.gg-navbar__portal-btn {
  margin-top: 2.5rem;
  padding: 8px 1.5rem;
}

@media (min-width: 992px) {
  .gg-navbar__nav .gg-navbar__link:not(:first-child) {
    margin-top: 0;
    margin-left: 1rem;
  }
  .gg-navbar__portal-btn {
    margin-top: 0;
  }
}

.offcanvas-header, .offcanvas-body {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  /* @media (min-width: $gg-screen-lg) {
        padding-left: 0;
        padding-right: 0;
    } */
}

.offcanvas-header .btn-close:hover,
.offcanvas-header .btn-close:focus {
  -webkit-box-shadow: none;
          box-shadow: none;
  color: #444343;
}

.gg-hero {
  position: relative;
  overflow: hidden;
  background-image: url("../assets/images/home/homepage_hero.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  height: calc(100vh - 4rem);
  color: #FFFFFF;
}

.gg-hero__mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-attachment: fixed;
  background-color: rgba(0, 0, 0, 0.6);
}

.gg-hero__text-wrap {
  margin-top: -6rem;
}

.gg-hero__heading {
  font-weight: 600;
}

.gg-hero__subhead {
  margin-top: 0.75rem;
  padding-left: 0.5rem;
  font-size: 1.5rem;
  line-height: 2.25rem;
}

.gg-hero__cta-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 2.75rem;
}

.gg-hero__cta-wrap .gg-btn {
  width: 200px;
}

.gg-hero__cta-wrap .gg-btn:not(:first-child) {
  margin-top: 1rem;
}

@media (min-width: 768px) {
  .gg-hero__cta-wrap {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    padding-left: 0.5rem;
  }
  .gg-hero__cta-wrap .gg-btn {
    width: auto;
  }
  .gg-hero__cta-wrap .gg-btn:not(:first-child) {
    margin-top: 0;
    margin-left: 1rem;
  }
}

.gg-bio {
  position: relative;
  background-color: #f4f3ef;
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(60%, rgba(112, 112, 112, 0)), to(rgba(186, 186, 186, 0.15)));
  background-image: linear-gradient(to bottom, transparent 0%, rgba(112, 112, 112, 0) 60%, rgba(186, 186, 186, 0.15) 100%);
}

.gg-bio__avatar {
  width: 15.5rem;
  height: 20rem;
  background-color: #CCD8DB;
  background-image: url("../assets/images/headshot.jpg");
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  -webkit-box-shadow: -50px -50px 0 -40px #85a1c1, 50px 50px 0 -40px #274b69;
          box-shadow: -50px -50px 0 -40px #85a1c1, 50px 50px 0 -40px #274b69;
}

.gg-bio__avatar img {
  width: 100%;
  height: 100%;
}

.gg-bio__heading {
  margin-top: 2.5rem;
}

.gg-bio__desc {
  margin-top: 2rem;
  padding: 0 1rem;
}

.gg-bio__desc p {
  font-size: 1.25rem;
  line-height: 1.65rem;
}

.gg-bio__desc p:not(:first-child) {
  margin-top: 1.5rem;
}

@media (min-width: 768px) {
  .gg-bio__desc {
    padding: 0;
  }
}

.gg-services {
  background-color: #CCD8DB;
}

.gg-services__wrap {
  margin-top: 4rem;
}

.gg-services__service {
  padding: 0 1rem;
}

.gg-services .col-md-6:not(:first-child) {
  margin-top: 3.5rem;
}

.gg-services__icon {
  font-size: 5rem;
}

.gg-services__name {
  margin-top: 1rem;
}

.gg-services__desc {
  margin-top: 1rem;
  font-size: 1.125rem;
  line-height: 1.5rem;
}

@media (min-width: 768px) {
  .gg-services__service {
    padding: 0 1.5rem;
  }
  .gg-services .col-md-6:not(:first-child) {
    margin-top: 0;
  }
  .gg-services .col-md-6:nth-child(n+3) {
    margin-top: 4.5rem;
  }
}

.gg-links {
  /* background: $gg-deep-blue;
    background: linear-gradient(to bottom, rgba(32,32,34,1) 70%, rgba(32,32,34,0.9) 100%); */
  background: #313030;
  color: #FFFFFF;
}

.gg-links__desc {
  margin-top: 1rem;
  padding: 0 1rem;
  font-size: 1.25rem;
  line-height: 1.65rem;
}

.gg-links__body {
  margin-top: 4rem;
}

.gg-links__body > div:not(:first-child) {
  margin-top: 3.5rem;
}

.gg-links__wrap {
  margin-top: 2rem;
  padding: 0 0.5rem;
}

.gg-links .gg-text-link:not(:first-child) {
  margin-top: 1.5rem;
}

@media (min-width: 768px) {
  .gg-links__desc {
    padding: 0;
  }
  .gg-links__body > div:not(:first-child) {
    margin-top: 0;
  }
}

.gg-contact {
  background-color: #f4f3ef;
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(60%, rgba(112, 112, 112, 0)), to(rgba(186, 186, 186, 0.15)));
  background-image: linear-gradient(to bottom, transparent 0%, rgba(112, 112, 112, 0) 60%, rgba(186, 186, 186, 0.15) 100%);
}

.gg-contact .container {
  max-width: 675px;
}

.gg-contact__form {
  margin-top: 4rem;
  padding: 0 1rem;
  position: relative;
}

.gg-contact__form-row:not(:first-child) {
  margin-top: 1rem;
}

.gg-contact__form-row > div:not(:first-child) {
  margin-top: 1rem;
}

.gg-contact__btn-wrap {
  margin-top: 1.75rem;
}

.gg-contact__spinner-wrap, .gg-contact__completed-wrap {
  position: absolute;
  top: 0;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #f4f3ef;
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(60%, rgba(112, 112, 112, 0)), to(rgba(186, 186, 186, 0.15)));
  background-image: linear-gradient(to bottom, transparent 0%, rgba(112, 112, 112, 0) 60%, rgba(186, 186, 186, 0.15) 100%);
}

.gg-contact__spinner-wrap {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.gg-contact__completed-wrap {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: start;
}

.gg-contact__spinner {
  height: 7rem;
  width: 7rem;
  border: 0.65rem solid #274b69;
  border-right-color: transparent;
  border-radius: 50%;
  margin: 0 auto;
  -webkit-animation: rotate 0.8s infinite linear;
          animation: rotate 0.8s infinite linear;
}

@media (min-width: 768px) {
  .gg-contact__form {
    padding: 0;
  }
  .gg-contact__form-row > div:not(:first-child) {
    margin-top: 0;
  }
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.gg-footer {
  background-color: #444343;
  padding: 2rem 0;
  color: #FFFFFF;
  /* &__contact svg {
        color: $gg-light-blue;
    } */
}

.gg-footer__contact {
  padding: 0 1rem;
}

.gg-footer__contact a {
  line-height: 1.75rem;
}

.gg-footer__contact a span {
  color: #FFFFFF;
}

.gg-footer__contact a:not(:first-child) {
  margin-top: 1.75rem;
}

.gg-footer__contact a:hover span {
  color: #addaeb;
}

.gg-footer__contact span {
  margin-left: 0.5rem;
}

.gg-footer__copyright {
  margin-top: 3rem;
  line-height: 1.75rem;
}

@media (min-width: 768px) {
  .gg-footer__contact {
    padding: 0;
  }
  .gg-footer__contact a:not(:first-child) {
    margin-top: 0;
    margin-left: 2rem;
  }
  .gg-footer__copyright {
    margin-top: 1.5rem;
  }
}

@media (min-width: 992px) {
  .gg-footer__copyright {
    margin-top: 0;
  }
}
