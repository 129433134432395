.gg-footer {
    // background-color: $gg-deep-blue;
    background-color: $gg-dark-gray;
    padding: 2rem 0;
    color: $white;

    &__contact {
        padding: 0 1rem;
    }

    &__contact a {
        line-height: 1.75rem;
    }
    
    &__contact a span {
        color: $white;
    }

    &__contact a:not(:first-child) {
        margin-top: 1.75rem;
    }

    &__contact a:hover span {
        color: $gg-link-blue-hover;
    }

    /* &__contact svg {
        color: $gg-light-blue;
    } */

    &__contact span {
        margin-left: 0.5rem;
    }

    &__copyright {
        margin-top: 3rem;
        line-height: 1.75rem;
    }

    @media (min-width: $gg-screen-md) {

        &__contact {
            padding: 0;
        }

        &__contact a:not(:first-child) {
            margin-top: 0;
            margin-left: 2rem;
        }

        &__copyright {
            margin-top: 1.5rem;
        }

    }

    @media (min-width: $gg-screen-lg) {
        
        &__copyright {
            margin-top: 0;
        }

    }

}