// Font Families
/* .fm-effra {
    font-family: 'Effra';
} */

// Font Weight Variables
$gg-fw-normal: 400;
$gg-fw-medium: 500;
$gg-fw-semibold: 600;
$gg-fw-bold: 700;
$gg-fw-extra-bold: 800;
$gg-fw-heavy: 900;

// Font Weight Classes
.fm-fw-normal {
    font-weight: $gg-fw-normal;
}

.fm-fw-medium {
    font-weight: $gg-fw-medium;
}

.fm-fw-semibold {
    font-weight: $gg-fw-semibold;
}

.fm-fw-bold {
    font-weight: $gg-fw-bold;
}

.fm-fw-xbold {
    font-weight: $gg-fw-extra-bold;
}

.fm-fw-heavy {
    font-weight: $gg-fw-heavy;
}