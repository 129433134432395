// Hero
.gg-hero {
    position: relative;
    overflow: hidden;
    // Photo source URL for crediting: https://unsplash.com/photos/XN4T2PVUUgk
    background-image: url('../assets/images/home/homepage_hero.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    height: calc(100vh - 4rem);
    color: $white;

    &__mask {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background-attachment: fixed;
        background-color: rgba(0, 0, 0, 0.6);
    }

    &__text-wrap {
        margin-top: -6rem;
    }

    &__heading {
        font-weight: $gg-fw-semibold;
    }

    &__subhead {
        margin-top: 0.75rem;
        padding-left: 0.5rem;
        font-size: 1.5rem;
        line-height: 2.25rem;
    }

    &__cta-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 2.75rem;
        
    }

    &__cta-wrap .gg-btn {
        width: 200px;
    }

    &__cta-wrap .gg-btn:not(:first-child) {
        margin-top: 1rem;
    }

    @media(min-width: $gg-screen-md) {

        &__cta-wrap {
            flex-direction: row;
            padding-left: 0.5rem;
        }

        &__cta-wrap .gg-btn {
            width: auto;
        }

        &__cta-wrap .gg-btn:not(:first-child) {
            margin-top: 0;
            margin-left: 1rem;
        }
    }
}
// End Hero