// Bio
.gg-bio {
    position: relative;

    // This isn't a fallback; the linear gradient lays atop this background color
    background-color: #f4f3ef;
    background-image: linear-gradient(to bottom,transparent 0%,rgba(112,112,112,0) 60%,rgba(186,186,186,0.15) 100%);

    &__avatar {
        //border: 0.25rem solid $gg-navy;
        //border-radius: 50%;
        width: 15.5rem;
        // height: 15.5rem;
        height: 20rem;
        background-color: $gg-light-gray;
        background-image: url('../assets/images/headshot.jpg');
        background-position: center top;
        // background-size: 10.25rem calc((10.25rem * 320)/229); // 229 x 320 img dimensions
        background-size: cover;
        background-repeat: no-repeat;
        overflow: hidden;
        box-shadow:
                -50px -50px 0 -40px $gg-light-blue,
                50px 50px 0 -40px $gg-blue;

        img {
            width: 100%;
            height: 100%;
        }
    }

    &__heading {
        margin-top: 2.5rem; 
    }

    &__desc {
        margin-top: 2rem;
        padding: 0 1rem;  
    }

    &__desc p {
        font-size: 1.25rem;
        line-height: 1.65rem;
    }

    &__desc p:not(:first-child) {
        margin-top: 1.5rem
    }

    @media (min-width: $gg-screen-md) {
        &__desc {
            padding: 0;
        }
    }

}
// END Bio